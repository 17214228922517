module.exports = {
	product: "#{product}",
	env: "#{env}",
	app: {
		basePath: "#{app:basePath}", // Don't prepend or append slashes
		resourcePath: "#{app:resourcePath}",
		availableRegions: "#{app:availableRegions}",
	},
	// API keys need to match the service key in Shield
	api: {
		acq: "#{api:acq}",
		acqc8: "#{api:acqc8}",
		acquisitions: "#{api:acquisitions}",
		cms: "#{api:cms}",
		comet: "#{api:comet}",
		cometchatgpt: "#{api:cometchatgpt}",
		cometfeedback: "#{api:cometfeedback}",
		content: "#{api:content}",
		contentExternal: "#{api:contentExternal}",
		contentKey: "#{api:contentKey}",
		discovery: "#{api:discovery}",
		diva: "#{api:diva}",
		epg: "#{api:epg}",
		eventplanner: "#{api:eventplanner}",
		horizon: "#{api:horizon}",
		horizonc8: "#{api:horizonc8}",
		internalepg: "#{api:internalepg}",
		internalmetadata: "#{api:internalmetadata}",
		internalvod: "#{api:internalvod}",
		main: "#{api:main}",
		metadata: "#{api:metadata}",
		metadatac8: "#{api:metadatac8}",
		orders: "#{api:orders}",
		planner: "#{api:planner}",
		plannerc8: "#{api:plannerc8}",
		press: "#{api:press}",
		pressBlobStorage: "#{api:pressBlobStorage}",
		pressProgramReviews: "#{api:pressProgramReviews}",
		reviews: "#{api:reviews}",
		reviewsScraping: "#{api:reviewsScraping}",
		schedules: "#{api:schedules}",
		scheduleChanges: "#{api:scheduleChanges}",
		selections: "#{api:selections}",
		shield: "#{api:shield}",
		sport: "#{api:sport}",
		star: "#{api:star}",
		star_c70: "#{api:star_c70}",
		star_c80: "#{api:star_c80}",
		tags: "#{api:tags}",
		tagsc8: "#{api:tagsc8}",
		traffic: "#{api:traffic}",
		trafficc8: "#{api:trafficc8}",
		vod: "#{api:vod}",
		vodcore: "#{api:vodcore}",
	},
	media: {
		image: "#{media:image}",
		star: "#{media:star}",
		prodimage: "#{media:prodimage}",
		uploadURL: "#{media:uploadURL}",
		playVideoURL: "#{media:playVideoURL}",
		brandedImageProxy: "#{media:brandedImageProxy}",
	},
	features: {
		// Media assets
		assetEditorWithTabs: "#{features:assetEditorWithTabs}",
		assetEditorHideRestrictions: "#{features:assetEditorHideRestrictions}",
		starDAMFeatures: "#{features:starDAMFeatures}",
		starDocumentAddonEnabled: "#{features:starDocumentAddonEnabled}",
		starUseMasonryLayout: "#{features:starUseMasonryLayout}",
		starMaximizeCropperSize: "#{features:starMaximizeCropperSize}",

		// Security
		skipApiTokens: "#{features:skipApiTokens}", // Do not try to look up auth tokens for use against the C60 API:s
		shieldTimerInterval: "#{features:shieldTimerInterval}", // Time in seconds. -1 = No timer
		moduleNameForShieldReset: "#{features:moduleNameForShieldReset}",
		enableModuleWideAccess: "#{features:enableModuleWideAccess}", // IN PROGRESS: Enables users with only module.access (ex shield.admin) to see and navigate to all configured apps for the module
		setCookieOnShieldLogin: "#{features:setCookieOnShieldLogin}",
		extraLoginPassword: "#{features:extraLoginPassword}",
		clearUserTokenCookies: "#{features:clearUserTokenCookies}",
		unlockUserDetailsForm: "#{features:unlockUserDetailsForm}",

		// Tracking
		enableSentryTracking: "#{features:enableSentryTracking}", // Track errors with Sentry
		sentryUrl: "#{features:sentryUrl}",
		trackUserDisplayAlertsInSentry: "#{features:trackUserDisplayAlertsInSentry}", // Also track the alerts we display to the user
		enableHeapTracking: "#{features:enableHeapTracking}", // Track user actions with Heap
		heapId: "#{features:heapId}",
		adobeAnalyticsURL: "#{features:adobeAnalyticsURL}",
		googleTagManagerId: "#{features:googleTagManagerId}",

		// Support
		enableSupportBeacon: "#{features:enableSupportBeacon}", // Add a support beacon which lets customers create Help Scout issues directly
		supportBeaconId: "#{features:supportBeaconId}",

		// Misc
		alertOnDirty: "#{features:alertOnDirty}", // Should the user be alerted when navigating away from a dirty editor. Remove when #stableOnleave
		acqLight: "#{features:acqLight}",
		acqC70: "#{features:acqC70}",
		acqC80: "#{features:acqC80}",
		acqDownloadRights: "#{features:acqDownloadRights}",
		acqAdsAllowed: "#{features:acqAdsAllowed}",
		bitmovinPlayerKey: "#{features:bitmovinPlayerKey}",
		checkVPNUrl: "#{features:checkVPNUrl}",
		documentClassName: "#{features:documentClassName}",
		showProgramFamilies: "#{features:showProgramFamilies}",
		enforceMetadataRatingBeforeApproval: "#{features:enforceMetadataRatingBeforeApproval}", // Enforce the rule to only allow approval if a valid age rating is picked
		metadataChatGPT: "#{features:metadataChatGPT}",
		metadataChatGPTEnabledForUsers: "#{features:metadataChatGPTEnabledForUsers}",
		metadataCognitiveServicesTranslation: "#{features:metadataCognitiveServicesTranslation}",
		metadataEventPlannerFilter: "#{features:metadataEventPlannerFilter}",
		metadataLocaleEditorSourceLanguageSmartness: "#{features:metadataLocaleEditorSourceLanguageSmartness}",
		metadataScheduleSkipChannels: "#{features:metadataScheduleSkipChannels}", // Don't render the channel picker
		metadataTranslationContentOwner: "#{features:metadataTranslationContentOwner}", // ContentOwner used to fetch programs that need translation
		metadataTranslationSourceLang: "#{features:metadataTranslationSourceLang}",
		metadataTranslationNewFilters: "#{features:metadataTranslationNewFilters}",
		metadataNewActionsLayout: "#{features:metadataNewActionsLayout}",
		metadataNewDefaultSearchBehaviour: "#{features:metadataNewDefaultSearchBehaviour}",
		metadataRightsInList: "#{features:metadataRightsInList}",
		metadataQCLink: "#{features:metadataQCLink}", // Link to QC player from metadata editor
		metadataQCBaseUrl: "#{features:metadataQCBaseUrl}",
		metadataQCConfigName: "#{features:metadataQCConfigName}",
		metadataEditorWarningMessageOnOpen: "#{features:metadataEditorWarningMessageOnOpen}",
		metadataEnableEmbargo: "#{features:metadataEnableEmbargo}",
		metadataEnableSynopsisEmbargo: "#{features:metadataEnableSynopsisEmbargo}",
		metadataFinnishRatings: "#{features:metadataFinnishRatings}",
		metadataOtherCountriesRatings: "#{features:metadataOtherCountriesRatings}",
		metadataC70: "#{features:metadataC70}",
		metadataC80: "#{features:metadataC80}",
		metadataLinearDefaultChannelGroup: "#{features:metadataLinearDefaultChannelGroup}",
		metadataPressSynopsisWYSIWYG: "#{features:metadataPressSynopsisWYSIWYG}",
		metadataProviderInGenericEditor: "#{features:metadataProviderInGenericEditor}",
		metadataSeriesAndEpisodesSeparateFilters: "#{features:metadataSeriesAndEpisodesSeparateFilters}",
		metadataVodHubProgramListFilter: "#{features.metadataVodHubProgramListFilter}",
		metadataUseCatalogues: "#{features:metadataUseCatalogues}",
		metadataUseRightTypeDisplayName: "#{features:metadataUseRightTypeDisplayName}",
		metadataCatalogues: "#{features:metadataCatalogues}",
		metadataSubtitleEditor: "#{features:metadataSubtitleEditor}",
		metadataSubtitleEditorAllowSaving: "#{features:metadataSubtitleEditorAllowSaving}",
		metadataSHBaseUrl: "#{features:metadataSHBaseUrl}",
		metadataEnableLocalAgeRatingInHayuNorwegianEditor: "#{features:metadataEnableLocalAgeRatingInHayuNorwegianEditor}",
		metadataDisplayPressTitleForCatalogues: "#{features:metadataDisplayPressTitleForCatalogues}",
		metadataStarContainerDisplayVideoFormats: "#{features:metadataStarContainerDisplayVideoFormats}",
		metadataPublisherTag: "#{features:metadataPublisherTag}",
		tagsC70: "#{features:tagsC70}",
		tagsC80: "#{features:tagsC80}",
		tagsShowBrandTags: "#{features:tagsShowBrandTags}",
		newVODFeatures: "#{features:newVODFeatures}",
		newCometNavigation: "#{features:newCometNavigation}",
		enableModuleSideDrawer: "#{features:enableModuleSideDrawer}",
		enableDarkModeSwitch: "#{features:enableDarkModeSwitch}",
		metadataUrl: "#{features:metadataUrl}",
		hideAppBar: "#{features:hideAppBar}",
		hideTV4Channels: "#{features:hideTV4Channels}",
		hideMTVChannels: "#{features:hideMTVChannels}",
		useWindowForLazyloadCalculations: "#{features:useWindowForLazyloadCalculations}",
		topWelcomeTitle: "#{features:topWelcomeTitle}",
		topWelcomeText: "#{features:topWelcomeText}",
		bottomWelcomeTitle: "#{features:bottomWelcomeTitle}",
		bottomWelcomeText: "#{features:bottomWelcomeText}",
		defaultFontFamily: "#{features:defaultFontFamily}",
		internalScheduleSearch: "#{features:internalScheduleSearch}", // Render program search (discovery) in Internal Schedule
		internalScheduleVodViewsEnabled: "#{features:internalScheduleVodViewsEnabled}",
		internalScheduleL2VEnabledServices: "#{features:internalScheduleL2VEnabledServices}",
		plannerEnableAddonViews: "#{features:plannerEnableAddonViews}",
		plannerHVOD: "#{features:plannerHVOD}",
		plannerC80: "#{features:plannerC80}",
		plannerEnableExcelExport: "#{features:plannerEnableExcelExport}",
		osdExtendedEditorForContentTodos: "#{features:osdExtendedEditorForContentTodos}",
		localRatingSource: "#{features:localRatingSource}",
		osdEnableExcel: "#{features:osdEnableExcel}",
		osdProductionCompanyRestrictions: "#{features:osdProductionCompanyRestrictions}",
		useMinimalImageEditor: "#{features:useMinimalImageEditor}",
		useContentSourceMetadata: "#{features:useContentSourceMetadata}",
		eventplannerDefaultCatchupAvailabilityPeriod: "#{features:eventplannerDefaultCatchupAvailabilityPeriod}",
		eventplannerDefaultPublishBeforeEventStart: "#{features:eventplannerDefaultPublishBeforeEventStart}",
		eventplannerDefaultPublishTimeOfDay: "#{features:eventplannerDefaultPublishTimeOfDay}",
		eventplannerScheduleChannels: "#{features:eventplannerScheduleChannels}",
		eventplannerDisabledTempForHERO: "#{features:eventplannerDisabledTempForHERO}",
		eventplannerContentConsumers: "#{features:eventplannerContentConsumers}",
		horizonExternalScheduleAssetUrlTemplate: "#{features:horizonExternalScheduleAssetUrlTemplate}",
		horizonPublishMetadataMaxDays: "#{features:horizonPublishMetadataMaxDays}",
		faviconPath: "#{features:faviconPath}",
		headerHighlightedTextBackgroundColor: "#{features:headerHighlightedTextBackgroundColor}",
		extendedMezzSupport: "#{features:extendedMezzSupport}",
		plannerDefaultPlatform: "#{features:plannerDefaultPlatform}",
		playVideoFromAzureMediaServices: "#{features:playVideoFromAzureMediaServices}",
		playVideoFromAzureMediaServicesForFormats: "#{features:playVideoFromAzureMediaServicesForFormats}",
		videoSourcesLookupAssetType: "#{features:videoSourcesLookupAssetType}",
		enableEditorNavigation: "#{features:enableEditorNavigation}",
		saveListFiltersInStorage: "#{features:saveListFiltersInStorage}",
		sanitizeTextInputs: "#{features:sanitizeTextInputs}",
		trafficC80: "#{features:trafficC80}",
		feedbackEnabled: "#{features:feedbackEnabled}",

		// Press
		pressOneTrustCookiesConsentId: "#{features:pressOneTrustCookiesConsentId}",
		pressBlobStorageLocale: "#{features:pressBlobStorageLocale}",
		pressLocale: "#{features:pressLocale}",
		pressRecaptchaSiteKey: "#{features:pressRecaptchaSiteKey}",
		pressTimezone: "#{features:pressTimezone}",
		pressLogoPath: "#{features:pressLogoPath}",
		pressSiteTitle: "#{features:pressSiteTitle}",
		discoveryPlatform: "#{features:discoveryPlatform}",
		pressSiteStructureFilename: "#{features:pressSiteStructureFilename}",
		pressEnableProgramReviews: "#{features:pressEnableProgramReviews}",
		pressAddVideoWatermark: "#{features:pressAddVideoWatermark}",
		pressDisableVideoPlayerOnProgramPage: "#{features:pressDisableVideoPlayerOnProgramPage}",
		pressUnauthorizedAssetMessage: "#{features:pressUnauthorizedAssetMessage}",
		pressRenderPressSynopsis: "#{features:pressRenderPressSynopsis}",
		pressEnableFooter: "#{features:pressEnableFooter}",
		pressClassName: "#{features:pressClassName}",
		pressHeaderScrollToLinks: "#{features:pressHeaderScrollToLinks}",
		pressSubscriptionsProviderId: "#{features:pressSubscriptionsProviderId}",
		pressRequireLogin: "#{features:pressRequireLogin}",
		pressHidePasswordAndRegisterLinks: "#{features:pressHidePasswordAndRegisterLinks}",
		pressMorePressReleasesLink: "#{features:pressMorePressReleasesLink}",
		pressDateFormatPressReleases: "#{features:pressDateFormatPressReleases}",
		pressLoginInputType: "#{features:pressLoginInputType}",
		pressEnableSearch: "#{features:pressEnableSearch}",
		pressCopyProgramInfoToClipboard: "#{features:pressCopyProgramInfoToClipboard}",
		pressDateMonthFormat: "#{features:pressDateMonthFormat}",
		registrationPrivacyPolicyAgreementLink: "#{features:registrationPrivacyPolicyAgreementLink}",

		// Selections
		selectionsItemEditorSTARAddon: "#{features:selectionsItemEditorSTARAddon}",
		selectionsUseTemplate: "#{features:selectionsUseTemplate}",
		selectionsMovedToHeroComet: "#{features:selectionsMovedToHeroComet}",

		// VOD
		defaultVodServiceId: "#{features:defaultVodServiceId}",
		vodServiceName: "#{features:vodServiceName}",
		vodEnableResendPay: "#{features:vodEnableResendPay}",

		// Schedules
		scheduleReleasesBundleFilter: "#{features:scheduleReleasesBundleFilter}",
		scheduleReleasesHideCreate: "#{features:scheduleReleasesHideCreate}",
		schedulesUseChannelNameInsteadOfReference: "#{features:schedulesUseChannelNameInsteadOfReference}",

		// Code path selection
		useFetchRequestRetries: "#{features:useFetchRequestRetries}", // Actually tries a request three times before throwing an error
		hideTokenErrors: "#{features:hideTokenErrors}", // Don't display red alerts or track to Sentry when the user recieves Token Errors (since they're not real errors)
		checkForUpdates: "#{features:checkForUpdates}", // Periodically checks for new UI releases by loading index.html and comparing the version numbers

		// SSO
		msalClientId: "#{features:msalClientId}",
		msalAuthority: "#{features:msalAuthority}",
		ssoEnabled: "#{features:ssoEnabled}",
	},
	acq: {
		internalWindowTypes: "#{acq:internalWindowTypes}",
	},
	metadata: {
		providerName: "#{metadata:providerName}",
	},
};