import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router'
import debounce from 'lodash/debounce'
import { browserHistory } from 'browserHistory'

import * as Alert from '../../../core/services/alert'

import App from '../../../components/app.jsx'
import Calendar from '../../../components/calendar'
import Const, { Regions } from '../../../core/constants'
import { hasAccessToPath } from '../../../core/services/auth'

import Header from './header'
import ProgramPicker from './programpicker'
import ProgramPickerHeader from './programpickerheader'
import ProgramDialog from './programDialog'

import Actions from '../actions'
import Store from '../store'

import './app.css'

@withRouter
class CalendarApp extends React.Component {

	constructor(props) {
		super(props);

		this.calendarRef = null;
		this.date = null;
		this.readParams();
		this.fetchDataDebounced = debounce(this.fetchData, 1000, {
			"leading": true,
			"trailing": true
		});
		this.debouncedProgramFilter = debounce(this.handleProgramFilter, 500);
		this.debouncedFilter = debounce(this.handleFilter, 500);

		const route = [{ module: "planner", access: "editor" }];
		this.readonly = !hasAccessToPath(route);
		this.isViewingEpisodes = false;
		this.isViewingUnfilteredPrograms = false;

		this.state = {
			selectedProgram: null,
			dirtyPublishWindowsIndex: {}, // Used for notifying the user about dirty publish windows
			...Store.getState(),
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		this.readParams();
		this.fetchDataDebounced();
		Actions.fetchItems("platforms");
		Actions.fetchItems("licenseTypes", this.state.list.licenseTypes.filters);
	}

	componentWillUnmount() {
		Store.unlisten(this.onChange);
	}

	componentDidUpdate(prevProps) {
		const date = this.props.params.date === "today" || !this.props.params.date ? moment() : moment(this.props.params.date);
		const prevDate = prevProps.params.date === "today" || !prevProps.params.date ? moment() : moment(prevProps.params.date);
		if (!date.isSame(prevDate, "day")) {
			this.readParams();
			this.fetchDataDebounced();
		}
	}

	onChange = (state) => {
		
		// Update dirtyPublishWindowsIndex and check if new items have been added, notify if so
		let notify = false;
		if (state?.list?.dirtyPublishWindows?.items) {
			const dirtyPublishWindowsIndex = {};
			state.list.dirtyPublishWindows.items.forEach(pw => {
				if (!this.state.dirtyPublishWindowsIndex[pw.id]) {
					notify = true;	
				}
				dirtyPublishWindowsIndex[pw.id] = pw;
			});
			state.dirtyPublishWindowsIndex = dirtyPublishWindowsIndex;
		}

		this.setState(state);

		if (notify) {
			const plural = state.list.dirtyPublishWindows.items.length > 1;
			Alert.displayAlert("warning", `There ${plural ? "are" : "is"} ${state.list.dirtyPublishWindows.items.length} ${plural ? "changes": "change"} ready for approval.`)
		}
	}

	readParams = () => {
		this.date = this.props.params.date === "today" || !this.props.params.date ? moment() : moment(this.props.params.date);
		if (this.calendarRef) {
			this.calendarRef.getApi().gotoDate(this.date.format());
		}
	}

	fetchData = () => {
		const { publishWindows, programs, activePublishWindowsToday } = this.state.list;
		const isCatalogueView = this.state.filters.calendarView === "catalogueView";

		if (isCatalogueView) {
			const runAfterFetchingCount = () => {
				// Active windows
				Actions.fetchItems("calendarPublishWindows", getPublishWindowPayload(this.state.filters, activePublishWindowsToday.filters, this.date, this.state.filters.calendarView, true, false, false), { targetStore: "activePublishWindowsToday" });
				// Windows with upcoming changes
				Actions.fetchItems("calendarPublishWindows", getPublishWindowPayload(this.state.filters, {}, this.date, this.state.filters.calendarView, false, true, false), { targetStore: "publishWindows" });
			};

			// Fetches the count for given publishWindows (singles/episodes)
			Actions.fetchItem("calendarPublishWindowsCount", getPublishWindowPayload(this.state.filters, {}, this.date, this.state.filters.calendarView, true, false, true), { targetStore: "activePublishWindowsTodayCount", callback: runAfterFetchingCount });
		} else {
			Actions.fetchItems("calendarPublishWindows", getPublishWindowPayload(this.state.filters, publishWindows.filters, this.date, this.state.filters.calendarView), { targetStore: "publishWindows" });
		}

		Actions.fetchItems("programs", getProgramsPayload(this.state.programFilters, programs.filters, this.date, this.state.filters.calendarView));
		getUnfilteredProgramsStats(getProgramsPayload(this.state.programFilters, programs.filters, this.date, this.state.filters.calendarView));
		this.fetchDirtyPublishWindows();
	}

	fetchDirtyPublishWindows = () => {
		const { dirtyPublishWindows } = this.state.list
		Actions.fetchItems(
			"publishWindows",
			{ ...dirtyPublishWindows.filters, pageSize: this.readonly ? 1 : undefined },
			{ targetStore: "dirtyPublishWindows" }
		);
	}

	// Event drop = event moved in calendar
	handleEventDrop = (event, revert) => {
		const { id, start } = event;
		const payload = {
			changedStart: moment(start).format(),
		};
		Actions.updatePublishWindow(id, payload, revert, () => {
			setTimeout(() => {
				this.fetchData();
			}, 0);
		});
	}

	// Program drop = program dragged from license list to calendar
	handleProgramDrop = (event) => {
		const { id: licenseId, start } = event;

		const payload = {
			changedStart: moment(start).format(),
			licenseId: parseInt(licenseId),
		};
		Actions.createPublishWindow(payload, null, () => {
			setTimeout(() => {
				this.fetchData();
				event.remove(); // remove the new event since we will fetch all events and we will otherwise end up with a duplicate
			}, 0);
		});
	}

	handleViewChange = view => {
		Actions.changeCalendarView(view);

		// We wan't to switch to today when using catalogue view
		if (view === "catalogueView") {
			this.date = moment();
			this.calendarRef.getApi().gotoDate(this.date.format());

			browserHistory.replace({
				pathname: `/planner/calendar/${this.date.format(Const.DATE_FORMAT)}`,
			});
		}

		this.calendarRef.getApi().changeView(view);
		this.fetchData();
	}

	onEventClick = ({ program }) => {
		this.setState({ selectedProgram: program });
	}

	onProgramClick = (program) => {
		this.setState({ selectedProgram: program });
	}

	handleEventRemoveClick = (event, viewType) => {
		// viewType is used to know which this.state.list we check for window match
		// listDay = activePublishWindowsToday list
		const isActivePublishWindowsTodayClick = viewType === 'listDay';

		const publishWindow = isActivePublishWindowsTodayClick
			? this.state.list.activePublishWindowsToday.items.find(pw => pw.id === parseInt(event.id))
			: this.state.list.publishWindows.items.find(pw => pw.id === parseInt(event.id));

		const { programId } = publishWindow;
		Actions.deletePublishWindow(publishWindow.id, () => {
			// If the publish window belongs to the license that we currently have opened in the license panel, refresh the license
			if (programId === this.state.selectedProgram?.id) {
				Actions.fetchItem("program", { id: programId });
			}
			this.fetchData();
		});
		event.remove();
	}

	handleViewEpisodes = (e, referenceId, rights, licenseType) => {
		e.stopPropagation();
		const rightIds = (rights ?? [])
			.filter(r => Regions.some(region => region.countryDisplayName === r.name))
			.map(r => r.id)
			.join(",");
		this.isViewingEpisodes = true;
		this.isViewingUnfilteredPrograms = false;
		// TODO: check that we use correct parent id
		Actions.fetchItems(
			"programs",
			getProgramsPayload(this.state.filters, {
				...this.state.list.episodes.filters,
				parentReferenceId: referenceId,
				licenseTypeId: licenseType?.id,
				rights: rightIds,
				owner: null,
			}),
			{ targetStore: "episodes" },
		);
	}

	handleBackToResults = () => {
		this.isViewingEpisodes = false;
		this.isViewingUnfilteredPrograms = false;

		Actions.itemsUpdated({
			store: "episodes",
			items: [],
		});
	}

	onProgramFilter = (type, e) => {
		if (type === "search") {
			e.persist();
			this.debouncedProgramFilter(type, e);
		}
		else {
			this.handleProgramFilter(type, e);
		}
	}

	handleProgramFilter = (type, event) => {
		Actions.itemsUpdated({
			store: "episodes",
			items: [],
		});
		
		const { name, value } = event.target;
		Actions[`${type}Programs`](value, name);
		this.isViewingEpisodes = false;
		this.isViewingUnfilteredPrograms = false;
		const payload = getProgramsPayload(this.state.programFilters, this.state.list.programs.filters, this.date);
		Actions.fetchItems("programs", payload);

		getUnfilteredProgramsStats(payload);

		this.setState((state) => ({
			programFilters: {
				...state.programFilters,
				searchText: type !== "search" ? state.programFilters.searchText : value,
			},
		}));

		// Force lazyload to load visible images
		window.dispatchEvent(window.customLazyEvent);
	}

	onFilter = (type, e) => {
		// if (type === "search") {
		// 	e.persist();
		// 	this.debouncedFilter(type, e);
		// }
		// else {
		this.handleFilter(type, e);
		// }
	}

	handleFilter = (type, event) => {
		const { name, value } = event.target;
		Actions[`${type}`](value, name, "publishWindows");
		Actions[`${type}Programs`](value, name);

		this.fetchData();

		this.setState((state) => ({
			filters: {
				...state.filters,
				searchText: type !== "search" ? state.filters.searchText : value,
			},
		}));

		// Force lazyload to load visible images
		window.dispatchEvent(window.customLazyEvent);
	}

	loadMorePrograms = () => {
		const programs = this.state.list.programs;

		if (programs.nextPageUrl) {
			Actions.pageItems("programs", programs.nextPageUrl);
		}
	}

	handleUnfilteredPrograms = () => {
		this.isViewingUnfilteredPrograms = true;
		// this.state.source = "";
		getUnfilteredPrograms(getProgramsPayload(this.state.programFilters, this.state.list.programs.filters, this.date, this.state.filters.calendarView));
	}

	render() {
		const {
			publishWindows,
			programs,
			licenseTypes,
			episodes,
			dirtyPublishWindows,
			platforms,
			activePublishWindowsToday,
			unfilteredProgramsStats,
			unfilteredPrograms,
		} = this.state.list;
		// We want to use the default passing of publishWindows for catalogueView and upcoming changes
		const isUpcomingChanges = this.state.filters.calendarView === "catalogueView";
		let events, activeEvents;
		if (isUpcomingChanges) {
			events = transformEvents(publishWindows.items, this.state.filters.filter, this.date);
			activeEvents = transformEvents(activePublishWindowsToday.items, this.state.filters.filter);
		} else {
			events = transformEvents(publishWindows.items, this.state.filters.filter);
		}

		return (
			<App name="c6-planner-calendar" isLoading={this.state.isLoading || publishWindows.isLoading || activePublishWindowsToday.isLoading || programs.isLoading}>
				<Header
					date={this.date}
					dirtyPublishWindows={dirtyPublishWindows}
					onViewChange={this.handleViewChange}
					filters={this.state.filters}
					publishWindowsFilters={publishWindows.filters}
					handleFilter={this.onFilter}
					readonly={this.readonly}
					platforms={platforms}
					isLoadingApproveOrUndo={this.state.isLoadingApproveOrUndo}
				/>
				<Calendar
					initialView={this.state.filters.calendarView}
					isCatalogueView={this.state.filters.calendarView === "catalogueView"}
					date={this.date}
					events={publishWindows.isLoading ? [] : events}
					activeTodayEvents={activePublishWindowsToday.isLoading ? [] : activeEvents}
					activeTodayEventsCount={this.state.item.activePublishWindowsTodayCount} // Count
					eventsTimestamp={publishWindows.latestRequestTime}
					activeEventsTimestamp={activePublishWindowsToday.latestRequestTime}
					onDateClick={dateStr => console.log("dateStr", dateStr)}
					onEventClick={this.onEventClick}
					onEventDrop={this.handleEventDrop}
					onEventRemoveClick={this.handleEventRemoveClick}
					onProgramDrop={this.handleProgramDrop}
					calendarRef={el => {
						this.calendarRef = el;
						this.calendarRef?.getApi().changeView(this.state.filters.calendarView);
					}}
					disabled={publishWindows.isLoading || activePublishWindowsToday.isLoading}
					readonly={this.readonly}
				/>
				<ProgramDialog
					programId={this.state.selectedProgram?.id}
					programReferenceId={this.state.selectedProgram?.referenceId}
					program={this.state.item.program}
					programChildren={this.state.list.programChildren.items}
					programChildrenIsLoading={this.state.list.programChildren.isLoading}
					selectProgram={this.onProgramClick}
					isLoading={this.state.item.isLoading && !this.state.item.program.id}
					onClose={() => this.onProgramClick(null)}
					onPublishWindowUpdated={this.fetchData}
					onPublishWindowDeleted={this.fetchData}
					onPublishWindowCreated={this.fetchData}
					readonly={this.readonly}
					licenseTypes={licenseTypes}
					fetchData={() => Actions.fetchItem("program", { id: this.state.selectedProgram?.id, reference: this.state.selectedProgram?.referenceId })}
					fetchEpisodes={() => Actions.fetchItems("programs", { parentReferenceId: this.state.item.program?.referenceId }, { targetStore: "programChildren" })}
				/>
				<ProgramPickerHeader
					filters={this.state.programFilters}
					handleFilter={this.onProgramFilter}
					date={this.date}
				/>
				<ProgramPicker
					programs={programs}
					episodes={episodes}
					unfilteredProgramsStats={unfilteredProgramsStats}
					unfilteredPrograms={unfilteredPrograms}
					onViewUnfilteredProgramsClick={this.handleUnfilteredPrograms}
					isViewingUnfilteredPrograms={this.isViewingUnfilteredPrograms}
					isViewingEpisodes={this.isViewingEpisodes}
					hasMore={this.isViewingEpisodes ? false : !!programs.nextPageUrl}
					loadMore={this.loadMorePrograms}
					onProgramClick={this.onProgramClick}
					onViewEpisodesClick={this.handleViewEpisodes}
					onBackToResultsClick={this.handleBackToResults}
					disabled={programs.isLoading}
					readonly={this.readonly}
					disableDrag={this.state.filters.calendarView?.includes("list")}
					filters={this.state.programFilters}
				/>
			</App>
		);
	}
}

export default CalendarApp;

function transformEvents(events, filter, date) {
	return [...events]
		.map(e => {
			let vodTypes = [];
			if (e.licenseTypeMonetizationModel) {
				vodTypes = [e.licenseTypeMonetizationModel];
			}
			if (e.exposureMonetizationModel !== "undefined") {
				vodTypes = [e.exposureMonetizationModel];
			}
			vodTypes = vodTypes.filter((v, i, a) => a.indexOf(v) === i);

			const editable = e.exposureMonetizationModel === "undefined";

			const event = {
				...e,
				end: e.constraint?.end,
				constraint: {
					startTime: e.constraint?.start,
					endTime: e.constraint?.end,
				},
				publishWindowEnd: e.end,
				publishWindowStart: e.start,
				className: `vod-type-${vodTypes[0]} priority-${e.priority} id-${e.id} ${editable ? "" : "prevent-move-and-remove"}`,
				vodTypes,
				preventMoveAndRemove: !editable,
				startEditable: editable,
			};

			if (date) {
				const isStarting = date.isBefore(moment(e.start)); // Is a start event, not a ending event, an event of addition and not removal.
				event.eventType = isStarting ? "add" : "remove";
				event.start = isStarting ? e.start : e.end; //If it's en ending event, the end of the window is of interest
				event.className += ` ${e.style} dirty-${e.className.includes("dirty")} starting-${isStarting}`;
			}

			return event;
		})
		.filter(e => !filter._monetizationModel || e.vodTypes.includes(filter._monetizationModel));
}

function getProgramsPayload(filters = {}, extra = {}, date, calendarView) {
	const { filter = {}, searchText, ...rest } = filters;
	const { start, end } = date ? getStartAndEnd(date, filter._premiereFilter, calendarView) : {};

	let programTypes = filter.programTypes;
	if (filter._expandSeasons) {
		programTypes = programTypes.replace("season", "episode");
	}

	const payload = {
		...filter,
		programTypes,
		...rest,
		...extra,
		search: searchText,
		start: start?.format(Const.DATE_FORMAT),
		end: end?.format(Const.DATE_FORMAT),
		monetizationModel: filter._monetizationModel,
		applyPlatformRightFilter: true,
	};

	if (searchText && searchText.length) {
		delete payload.start;
		delete payload.end;
		// 2022-06-27 HACK: Planner panic fix because upcoming licenses were not included in search
		// When searching, set end to a date far away because the backend will not use the "start" filter unless "end" is also available
		payload.activeUntilFrom = moment().format(Const.DATE_FORMAT);
		delete payload.filter;
		delete payload.premieres;
	}

	delete payload.calendarView;
	return payload;
}

function getUnfilteredProgramsStats(payload) {
	if (payload.search && payload.search.length) {
		delete payload.owner;
		payload.onlyunplanned = false;
		delete payload.start;
		delete payload.end;

		Actions.fetchItems("programs", 
			{ ...payload, pageSize: 1 }, 
			{ targetStore: "unfilteredProgramsStats" });
	}
}

function getUnfilteredPrograms(payload) {
	if (payload.search && payload.search.length) {
		delete payload.owner;
		payload.onlyunplanned = false;
		delete payload.start;
		delete payload.end;

		Actions.fetchItems("programs", 
			{ ...payload }, 
			{ targetStore: "unfilteredPrograms" });
	}
}

export function getPublishWindowPayload(filters = {}, extra = {}, date, calendarView, isActiveToday, isUpcomingChanges, isActiveCount) {
	const { filter = {}, ...rest } = filters;
	const startOf = calendarView?.toLowerCase().includes("week") ? "isoWeek" : "month";
	const start = date.clone().startOf(startOf);
	const end = date.clone().endOf(startOf);

	let programTypes = filter.programTypes;
	// We don't want episodes to be shown in the Catalogue view, but we still want to count them if all/series were picked
	if (programTypes.includes("season") && (!isActiveToday || isActiveCount)) { 
		programTypes += "|episode";
	}

	const payload = {
		...filter,
		programTypes,
		...rest,
		...extra,
		// monetizationModel: calendarView === "catalogueView" ? filter._monetizationModel : null,
		applyPlatformRightFilter: true,
		start: (!isActiveToday && !isUpcomingChanges) ? start.format(Const.DATE_FORMAT) : null, // Null if we don't care about these.
		end: (!isActiveToday && !isUpcomingChanges) ? end.format(Const.DATE_FORMAT) : null,
		IsActiveOn: isActiveToday ? start.format(Const.DATE_FORMAT) : null, // To fetch all publishWindows that are active today
		upcomingChanges: isUpcomingChanges ? isUpcomingChanges : null,
		upcomingChangesDaysAhead: isUpcomingChanges ? 35 : null, // 168 days = 24 weeks = 6 months
	};

	delete payload.calendarView;

	return payload;
}

function getStartAndEnd(date, premiereFilter, calendarView) {
	let start = date, end = date;
	const unit = calendarView?.toLowerCase().includes("week") ? "week" : "month";
	if (premiereFilter === "fresh") { // fresh
		start = date.clone().subtract(1, unit).startOf("month");
		end = date.clone().subtract(1, unit).endOf("month");
	} else if (premiereFilter === "upcoming") { // upcoming
		start = date.clone().startOf("month");
		end = date.clone().endOf("month");
	}

	return { start, end };
}