import alt from '../../core/services/alt'
import * as APIS from '../../apis/'
import * as Alert from '../../core/services/alert'
import AuthStore from '../../core/authentication/store'

const API = "planner";

class Actions {

	search(searchText, filterName, targetStore) {
		return (dispatch) => {
			dispatch({
				searchText,
				targetStore,
			});
		}
	}

	filter(filterValue, filterName, targetStore) {
		return (dispatch) => {
			dispatch({
				[filterName]: filterValue,
				targetStore,
			});
		};
	}

	searchPrograms(searchText) {
		return (dispatch) => {
			dispatch({ searchText });
		}
	}

	filterPrograms(filterValue, filterName) {
		return (dispatch) => {
			dispatch({ [filterName]: filterValue });
		};
	}

	changeCalendarView(view) { return view }

    /* **************** Items **************** */
    fetchItems(entity, payload, options = {}) {
        return (dispatch) => {
            const {
                targetStore = null,
                callback = null,
                api = API,
            } = options;

            const requestTime = Date.now();
            const multiple = true;
            const command = getCommand("fetch", entity, multiple);
            const store = targetStore || getStore(entity, multiple);

            dispatch({ payload, store, requestTime });

            APIS[api][command](payload)
                .then(response => {
					// C60
                    const { pageIndex, numberOfItems, items, links } = response;
					const nextPageUrl = getNextPageUrl(links);

					// // C70
                    // const { result: items, meta } = response;
					// const { pageIndex, totalItems: numberOfItems, next: nextPageUrl } = meta;

                    const appendToExistingItems = pageIndex > 0;

                    this.itemsUpdated({
                        store,
                        items,
                        appendToExistingItems,
                        numberOfItems,
                        nextPageUrl,
                        requestTime,
                    });

                    if (callback) {
                        callback(items);
                    }

                }, this.requestFailed);

        };
    }
    pageItems(entity, url, options = {}) {
        return (dispatch) => {
            const {
                targetStore = null,
                callback = null,
                api = API,
            } = options;

            const requestTime = Date.now();
            const multiple = true;
            const store = targetStore || getStore(entity, multiple);

            dispatch({ entity, url, store });

            APIS[api].fetchUrl(url)
                .then(response => {
					// C60
                    const { pageIndex, numberOfItems, items, links } = response;
					const nextPageUrl = getNextPageUrl(links);

					// C70
					// const { result: items, meta } = response;
					// const { pageIndex, totalItems: numberOfItems, next: nextPageUrl } = meta;

                    const appendToExistingItems = pageIndex > 0;

                    this.itemsUpdated({
                        store,
                        items,
                        appendToExistingItems,
                        numberOfItems,
                        nextPageUrl,
                        requestTime,
                    });

                    if (callback) {
                        callback(items);
                    }

                }, this.requestFailed);
        };
    }
    itemsUpdated(payload) {
        return payload;
    }

    /* **************** Item **************** */
	fetchItem(entity, payload, options = {}) {
		return (dispatch) => {
			const {
                targetStore = entity,
                callback = null,
                api = API,
			} = options;

			dispatch(targetStore);

			const command = getCommand("fetch", entity);
			APIS[api][command](payload)
				.then(model => {
					this.itemLoaded({ entity: targetStore, model });
					if (callback) {
						callback();
					}
				}, this.requestFailed);
		};
    }

    itemLoaded(payload) { return payload; }

	updateItem(entity, data, payload, type = "update", targetStore) {
		return (dispatch) => {
			dispatch();

			const command = getCommand(type, entity);
			APIS[API][command](data, payload)
				.then(model => {
					if (model) { // Some requests have empty responses in C60 Planner
						this.itemUpdated({ entity, model, originalId: data.id, targetStore });
					}
				}, this.requestFailed);
		}
    }
    itemUpdated(payload) { return payload; }

    removeItem(entity, data, targetStore) {
		return (dispatch) => {
			dispatch({ entity, id: data.id, targetStore });

            const command = getCommand("delete", entity);
			APIS[API][command](data)
				.then(() => {
					this.itemRemoved({ entity, id: data.id, targetStore });
				}, (error) => {
					// TODO!: Keep item index when rollbacking
					this.rollbackRemoveItem({ entity, id: data.id, targetStore });
                    this.requestFailed(error);
				});
		};
    }

    itemRemoved(payload) { return payload; }

    rollbackRemoveItem(payload) { return payload; }

    /* ================ */
	/*      GENERAL     */
	/* ================ */
    unmount() { return true }

    persist(model, store, prepend) {
        return { store, model, prepend };
	}

	requestFailed(error, revert) {
		Alert.displayAlert("error", error.exceptionMessage);
		if (typeof(revert) === "function") {
			revert();
		} 
		return true;
	}

	// CUSTOM
	createPublishWindow(payload, revert, callback, licenseId, programId) {
		return () => {
			payload.changedByUser = getUsername();
			APIS[API].createPublishWindow(payload)
				.then(model => {
					if (callback) {
						callback(model, licenseId, programId);
					}
					this.publishWindowCreated({ model, licenseId, programId });
				}, error => {
					this.requestFailed(error, revert);
					if (callback) {
						callback();
					}
				});
		}
	}

	publishWindowCreated({ model, licenseId, programId }) { return { model, licenseId, programId } }

	updatePublishWindow(id, payload, revert, callback, licenseId, programId) {
		return () => {
			payload.changedByUser = getUsername();
			APIS[API].updatePublishWindow({ id }, payload)
				.then(model => {
					if (callback) {
						callback(model, licenseId, programId);
					}
					this.publishWindowUpdated({ model, licenseId, programId });
				}, error => this.requestFailed(error, revert));
		}
	}

	publishWindowUpdated({ model, licenseId, programId }) { return { model, licenseId, programId } }

	deletePublishWindow(id, callback, licenseId, programId) {
		const filters = {
			changedByUser: getUsername()
		};
		return () => {
			APIS[API].deletePublishWindow({ id }, filters)
				.then(() => {
					if (callback) {
						callback(id, licenseId, programId);
					}
					this.publishWindowDeleted({ id, licenseId, programId });
				}, this.requestFailed);
		}
	}

	publishWindowDeleted({ id, licenseId, programId }) { return { id, licenseId, programId } }

	toggleSelectedChange(id) { return id }
	selectAllChanges() { return true }
	selectNoChanges() { return true }
	selectChangesByUser() { return true }
	selectChanges(changes) { return changes }
	approveOrUndoFinished() { return true }
	approveChanges(payload, filters) {
		return dispatch => {
			dispatch();

			APIS[API].approvePublishWindows(payload)
				.then(() => {
					this.approveOrUndoFinished();
					this.fetchItems("publishWindows", filters, { targetStore: "dirtyPublishWindows" });
				}, (error) => {
					this.approveOrUndoFinished();
					this.requestFailed(error);
				});
		};
	}

	undoChanges(payload, filters) {
		return dispatch => {
			dispatch();

			APIS[API].undoPublishWindowsChanges(payload)
				.then(() => {
					this.approveOrUndoFinished();
					this.fetchItems("publishWindows", filters, { targetStore: "dirtyPublishWindows" });
				}, (error) => {
					this.approveOrUndoFinished();
					this.requestFailed(error);
				});
		}
	}

	updateLicense(id, payload, programId, onLicenseUpdated) {
		return dispatch => {
			dispatch();

			APIS[API].updateLicense({ id }, payload)
				.then(response => {
					if (response) {
						this.licenseUpdated(response, programId);
						if (onLicenseUpdated) {
							onLicenseUpdated(response, id, programId);
						}
					}
				}, this.requestFailed);
		}
	}

	licenseUpdated(license, programId) { return { license, programId } }

	updateProgram(id, payload) {
		return dispatch => {
			dispatch();

			APIS[API].updateProgram({ id }, payload)
				.then(response => {
					this.programLoaded(response);
				}, this.requestFailed);
		}
	}

	programLoaded(program) { return program }
}

export default alt.createActions(Actions);

// Helpers
function getCommand(command, entity, multiple = false) {
	if (typeof(entity) === "object") {
		const extra = multiple ? "s" : "";

		if (entity.parentEntity) {
			return command + entity.parentEntity.substr(0, 1).toUpperCase() + entity.parentEntity.substr(1) + entity.entity.substr(0, 1).toUpperCase() + entity.entity.substr(1) + extra;
		}
		entity = `${entity.entity}${extra}`;
	}
	return command + entity.substr(0, 1).toUpperCase() + entity.substr(1);
}

function getStore(entity, multiple = false) {
	if (typeof(entity) === "object") {
		const extra = multiple ? "s" : "";
		return `${entity.entity}${extra}`;
	}
	return entity;
}

function getNextPageUrl(links = []) {
	const nextLink = links.find(l => l.rel === "next" || l.Rel === "next");

	return nextLink
		? nextLink.href || nextLink.Href
		: null;
}

function getUsername() {
	const user = AuthStore.getState().user;
	return user ? user.username : "Unknown";
}